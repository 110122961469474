@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");

:root {
  --background-color: #171717;
  --spotify-color: #1db954;
  --spotify-dark-color: #06491e;
  --spotify-light-color: #14ff67;
  --remove-color: #c00060;
  --color1: #5acee3;
  --color2: #f3f3f3;
  --color3: #666666;
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* prevent tap highlight color / shadow */
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  user-select: none;
}

body {
  min-height: calc(100vh - 16px);
  height: calc(100vh - 16px);
  background-color: var(--background-color);
}

#root {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
}

.status {
  position: relative;
  bottom: 180px;
  color: white;
}

div.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}

div.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}

button {
  padding: 12px 20px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: 0.25s;
}

button:hover {
  cursor: pointer;
  background-color: white;
  color: var(--background-color);
  box-shadow: 0 0.5em 0.5em -0.4em white;
  transform: translateY(-0.25em);
}

div.send-button {
  display: flex;
  position: absolute;
  bottom: 20%;
  align-items: center;
  height: 50px;
}

button.send-button {
  height: 100%;
}

button.clear-button {
  height: 100%;
  padding: 10px;
  border-color: var(--remove-color);
  color: var(--remove-color);
}

button.clear-button:hover {
  background-color: var(--remove-color);
  color: white;
  box-shadow: 0 0.5em 0.5em -0.4em var(--remove-color);
}

svg {
  flex-shrink: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

a.icon {
  line-height: 0;
}

a.highlight::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--spotify-color);
  transition: width 0.3s;
}

a.highlight:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.extra-bold {
  font-weight: 900;
}

.thin {
  font-weight: 300;
}

.title {
  letter-spacing: 0.2rem;
}

.green {
  color: var(--spotify-color);
}

.text-light {
  color: var(--color3);
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6e1d9;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bfad;
}

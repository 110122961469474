div.songs-container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color2);
  width: 550px;
  max-width: 100%;
  position: relative;
  top: 95%;
  z-index: 1;
  backdrop-filter: blur(6px);
  height: fit-content;
  max-height: 90%;
}

div.song-record.highlight {
  border: var(--spotify-color) 2px solid;
}

div.songs-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  gap: 18px;
  width: 100%;
  max-width: 100%;
  height: 700px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

div.song-record {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  transition: 0.25s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  min-height: 70px;
  height: 70px;
}

div.song-record p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

div.song-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

img.song-cover {
  object-fit: cover;
  width: 70px;
  margin: -20px;
  margin-right: 0;
}

.song-info {
  min-width: 70px;
}

svg.spotify-icon {
  color: #4b4b4b;
  transition: all 0.4s ease 0s;
}

svg.spotify-icon:hover {
  color: var(--spotify-color);
  cursor: pointer;
}

.song-record:hover {
  box-shadow: rgba(0, 102, 14, 0.4) 0px 3px 8px;
}

header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  padding: 10px calc(10px + 5%);
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 4;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 30px;
}

.link-icon {
  color: white;
  transition: all 0.2s;
}

.link-icon:hover {
  color: #c9c9c9;
  cursor: pointer;
}

.menu {
  position: fixed;
  top: 90px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  z-index: 4;
  color: white;
}

.menu.repositories {
  right: calc(10px + 5%);
}

.menu.bibliography {
  right: min(calc(75px + 5%), calc(100% - 300px));
  width: 300px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
}

.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.menu-item p {
  margin: 0;
  font-size: 12px;
}

div.logo {
  display: flex;
  align-items: center;
  gap: 20px;
}

img.logo {
  height: 40px;
}

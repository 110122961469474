div.progress-bar {
  background-color: var(--color2);
  width: 60px;
  max-width: 60px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
}

div.progress-bar p {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
}

div.progress-bar-fill {
  background: linear-gradient(
    to right,
    var(--spotify-color),
    var(--spotify-light-color)
  );
  height: 100%;
  border-radius: 6px;
  transition: 0.25s;
}
